.email-signup-container .social-links li {
  padding-right: 0.3em;
}
.email-signup-container .footer-container h3 {
  font-size: 1.2rem;
}

.email-signup-wrapper {
  padding: 1rem;
  margin-bottom: 15rem;
}
.email-signup-wrapper .required-indicator {
  font-size: 1.2rem;
}
.email-signup-wrapper h3 {
  text-transform: uppercase;
}
.email-signup-wrapper .form-row {
  width: 31%;
  float: left;
  margin: 0 2rem 1rem 0;
  position: relative;
}
@media only screen and (min-width: 32rem) and (max-width: 75rem) {
  .email-signup-wrapper .form-row {
    width: 100%;
  }
}
.email-signup-wrapper .form-row:nth-child(3n) {
  margin: 0 0 1rem;
}
.email-signup-wrapper .form-row span {
  text-transform: uppercase;
}
.email-signup-wrapper .form-row span.error,
.email-signup-wrapper .form-row .error-message {
  bottom: -1.2rem;
  font-size: 1.1rem;
  left: 0;
  margin: 0;
  position: absolute;
  text-transform: none;
  border: 0 none;
  background: 0 none;
  width: auto;
}
.email-signup-wrapper .form-row .field-wrapper {
  position: relative;
  text-transform: uppercase;
}
.email-signup-wrapper .form-row .input-radio {
  margin: 1rem 2%;
}
.email-signup-wrapper .form-row .input-select {
  border: 0.1rem solid #aaa;
}
.email-signup-wrapper .full-width {
  width: 100%;
  float: left;
  margin: 2rem 0;
}
.email-signup-wrapper .full-width .button {
  text-transform: uppercase;
}
@media only screen and (min-width: 32rem) and (max-width: 75rem) {
  .email-signup-wrapper .full-width .button {
    width: 100%;
  }
}
.email-signup-wrapper .emarsyssignup-wrapper .form-row:nth-child(3n) {
  margin: 0 2rem 1rem 0;
}

#emarsys-newsletter-subscription .fa-envelope {
  background: #aaa;
  border: none;
  height: 32px;
  padding: 0;
  width: 32px;
}
#emarsys-newsletter-subscription .fa-envelope:hover {
  color: #fff;
}
#emarsys-newsletter-subscription .input-text {
  width: 16rem;
  float: left;
}
@media only screen and (min-width: 75.1rem) and (max-width: 102.3rem) {
  #emarsys-newsletter-subscription .input-text {
    width: 12rem;
  }
}

.privacy-checkbox {
  float: left;
}
.privacy-checkbox.input-checkbox {
  margin: 0 1% 3% 0;
}

.registration-form-wrapper {
  padding: 1rem;
  min-height: 55rem;
}
.registration-form-wrapper h6 {
  font-size: 1.1rem;
}

.show-confirmation-message {
  padding: 1rem;
  box-sizing: border-box;
  min-height: 55rem;
}
.show-confirmation-message h6 {
  font-size: 1.1rem;
}

.error-privacy {
  color: #900;
}